import React from 'react';

import map from './usamap.svg';

class Map extends React.Component {
    componentDidMount() {

        document.title = 'Craigstopher Gadtrout'
        this.updateCanvas();
    }
    updateCanvas() {
        const canvas = this.refs.canvas
        const ctx = canvas.getContext('2d');
        // canvas.width  = window.innerWidth;
        // canvas.height = window.innerHeight - 200;

        canvas.width  = 1200
        canvas.height = 687
        const background = new Image();
        background.src = map
        background.onload = () => {
            ctx.drawImage(background, 0, 0, canvas.width, canvas.height);
            const coords = this.props.coords;
            // draw lines
            ctx.beginPath();
            ctx.lineWidth   = 5;
            ctx.fillStyle = "#FF0000";
            ctx.strokeStyle = "#000000";
            [].concat(coords, coords[0]).forEach(function(coord) {
                const x = coord.x * canvas.width;
                const y = coord.y * canvas.width;
                ctx.lineTo(x, y);
                ctx.stroke();
                ctx.moveTo(x, y);
            });
            // draw circles
            coords.forEach(function(coord) {
                const x = coord.x * canvas.width;
                const y = coord.y * canvas.width;
                ctx.beginPath();
                ctx.arc(x, y, 10, 0, 2*Math.PI);
                ctx.fill();
                ctx.stroke();
            });
        }
    }
    render() {
        return <canvas ref="canvas" className="App-map" alt="map" />
    }
}

export default Map;
