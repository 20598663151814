import React from 'react';
import logo from './logo.png';
import Map from './map.js';
import './App.css';

const craig   = {x: 0.85, y: 0.15};
const stopher = {x: 0.85, y: 0.155};
const gad     = {x: 0.07, y: 0.25};
const trout   = {x: 0.77, y: 0.31};

const coords = [craig, stopher, gad, trout];

function App() {
  return (
    <div className="App">
      <header className="App-header">
          <p/>
          <img src={logo} className="App-logo" alt="logo" />
          <Map coords={coords}/>
      </header>
    </div>
  );
}

export default App;
